import { useEffect, useState } from "react";

import ReviewItem from "./ReviewItem";
import { useTranslation } from "react-i18next";
import goog from "../imgs/google.png";
import gyg from "../imgs/gyg.png";
import trip from "../imgs/trip.svg";
const Reviews = ({ map }) => {
  const { t } = useTranslation();
  const reviewsData = [
    {
      author_name: "Google",

      language: "en",
      profile_photo_url: goog,

      rating: 5,

      text: "We had a lovely experience with Duna Cruise. We booked last minute but luckily we got the sunset hour which is apparently very popular. Although we didn't get to see that beautiful sunset we still had an amazing experience. The boat itself was very clean and the staff were very kind and polite. The cruise was pleasant and we thoroughly enjoyed. Definitely recommend.",
      time: 1691766504,
    },
    {
      author_name: "Google",

      language: "en",

      profile_photo_url: goog,
      rating: 5,

      text: "Beautiful boat, not overcrowded and complimentary drink on arrival is a lovely touch. Fantastic way to see Budapest, a must do while visiting. Really recommend!",
      time: 1691145443,
    },

    {
      author_name: "Google",
      author_url: "#5d4037",
      profile_photo_url: goog,
      language: "en",

      rating: 5,

      text: "The company is very reliable, they deliver what they promise, the ambiance is fairly relaxed.🥂",
      time: 1680170169,
    },
    {
      author_name: "Google",
      profile_photo_url: goog,

      language: "en",

      rating: 5,

      text: "A glass of Hungarian fizz and a 1 hour boat ride mercifully free of a guided tour. Open on the top deck or A/C downstairs. Peaceful and potentially romantic hour.",
      time: 1680170169,
    },

    {
      author_name: "Google",
      profile_photo_url: goog,

      language: "en",

      rating: 5,

      text: "Lovely short tour along the river. Great for seeing some of the best sights in Budapest. Crew is very friendly and welcoming.",
      time: 1680170169,
    },
    {
      author_name: "Tripadvisor",
      isTrip: true,

      language: "en",
      profile_photo_url: trip,
      rating: 5,

      text: "Absolutely worth it! We went for the night cruise and it was the best way to end our time in Budapest. Staff were very attentive and kept everything clean. Provided blankets for the cold and ship was very comfortable.",
    },
    {
      author_name: "Tripadvisor",
      isTrip: true,

      language: "en",
      profile_photo_url: trip,
      rating: 5,

      text: "Great views of the city at night. The cruise was nice and relaxing. The 1 hour long trip was the perfect length of time for us!",
    },
    {
      author_name: "Tripadvisor",
      isTrip: true,

      language: "en",
      profile_photo_url: trip,
      rating: 5,

      text: "Beautiful way to see the city at night, all of the lights illuminate the historical sites. Lovely welcome drink of sparkling wine or juice is offered. Blankets are provided to keep you warm.",
    },
    {
      author_name: "Tripadvisor",
      isTrip: true,

      language: "en",
      profile_photo_url: trip,
      rating: 5,

      text: "This was a fantastic cruise on the Danube in great style. The historic boat was lovely and the staff excellent. We would thoroughly recommend this trip.",
    },
    {
      author_name: "Tripadvisor",
      isTrip: true,

      language: "en",
      profile_photo_url: trip,
      rating: 5,

      text: "A relaxing time away from the busy streets! Beautiful view, beautiful boat, beautiful city. Just perfect after walking all day.",
    },
    {
      author_name: "Tripadvisor",
      isTrip: true,

      language: "en",
      profile_photo_url: trip,
      rating: 5,

      text: "We had two fantastic tours with Duna Cruises last year so it was great to be back again. The staff are always so friendly and helpful and the trip very special. Thanks again for another superb daytime trip! We cannot recommend this trip enough, a must see if visiting Budapest",
    },
    {
      author_name: "Get Your Guide",

      language: "en",
      profile_photo_url: gyg,

      rating: 5,

      text: "Absolutely the best cruise! Easy to find, highly professional service, air conditioners inside, wonderful prosecco & cocktails!",
      time: 1680170169,
    },

    {
      author_name: "Google",

      language: "en",
      profile_photo_url: goog,

      rating: 5,

      text: "Nice experience and must do. Suggest booking tickets online. The waiting period was too much maybe because of the crowd. But overall we loved the experience. Suggest to do a night cruise.",
      time: 1680170169,
    },

    {
      author_name: "Google",

      language: "en",

      rating: 5,
      profile_photo_url: goog,

      text: "Simple but very pleasant evening cruise where we viewed the most beautiful riverside buildings in Budapest while sipping a glass of prosecco.",
      time: 1680170169,
    },
    {
      author_name: "Google",
      profile_photo_url: goog,

      language: "en",

      rating: 5,

      text: "A lovely way to spend an hour. Well worth doing when the weathers good. Reasonable price too",
      time: 1680170169,
    },
    {
      author_name: "Google",

      language: "en",
      profile_photo_url: goog,

      rating: 5,

      text: "Loved this experience! Definitely go for the night cruise. The city is beautiful all lit up. Relaxing, good prices, romantic and only an hour.",
      time: 1680170169,
    },
  ];
  const shuffle = (arr) => {
    let currentIndex = arr.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex > 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [arr[currentIndex], arr[randomIndex]] = [arr[randomIndex], arr[currentIndex]];
    }

    return arr;
  };
  const [shuffled, setShuffled] = useState(reviewsData);
  useEffect(() => {
    setShuffled(shuffle(reviewsData));
  }, []);

  return (
    <>
      <div id="reviews">
        <h2>{t("main1.reviews.title")}</h2>
        <div className="reviews-section">
          {shuffled && shuffled.slice(0, 5).map((e, i) => <ReviewItem data={e} key={i} />)}
        </div>
      </div>
    </>
  );
};
export default Reviews;
