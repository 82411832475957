import React, { useState, useEffect, useContext } from "react";
import hp from "../imgs/icons/headphones.svg";
import maphu from "../imgs/route-hu.png";
import mapen from "../imgs/route-en.png";
import balna from "../imgs/icons/white/icon-balna.svg";
import budaivar from "../imgs/icons/white/icon-budapalace.svg";
import furdo from "../imgs/icons/white/icon-gellertfurdo.svg";
import gresham from "../imgs/icons/white/icon-gresham.svg";
import halasz from "../imgs/icons/white/icon-halaszbastya.svg";
import mupa from "../imgs/icons/white/icon-mupa.svg";
import muszaki from "../imgs/icons/white/icon-muszaki.svg";
import nemzeti from "../imgs/icons/white/icon-nemzeti-szinhaz.svg";
import parli from "../imgs/icons/white/icon-parlament.svg";
import szabadsag from "../imgs/icons/white/icon-szabadsag.svg";
import templom from "../imgs/icons/white/icon-templom.svg";
import vasar from "../imgs/icons/white/icon-vasarcsarnok.svg";

import i18n from "i18next";
import en from "../imgs/flags/eng.svg";
import de from "../imgs/flags/ger.svg";
import zh from "../imgs/flags/chi.svg";
import fr from "../imgs/flags/fra.svg";
import it from "../imgs/flags/ita.svg";
import ru from "../imgs/flags/rus.svg";
import es from "../imgs/flags/spa.svg";
import ro from "../imgs/flags/rom.svg";
import kor from "../imgs/flags/kor.svg";
import grc from "../imgs/flags/grc.svg";
import hu from "../imgs/flags/hun.svg";
import cs from "../imgs/flags/cze.svg";
import pl from "../imgs/flags/pol.svg";

import poiimg1 from "../imgs/sights/balna.jpg";
import poiimg2 from "../imgs/sights/parlament.jpg";
import poiimg3 from "../imgs/sights/gellert.jpg";
import poiimg4 from "../imgs/sights/lanchid.jpg";
import poiimg5 from "../imgs/sights/mupa.jpg";
import poiimg6 from "../imgs/sights/nemzeti.jpg";
import poiimg7 from "../imgs/sights/szabadsag.jpg";
import phone from "../imgs/guide_phone.png";
import { Trans } from "react-i18next";

const MapRoutes = ({ t }) => {
  const [firstclick, setfirstclick] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    let isMounted = true;
    window.addEventListener("resize", () => {
      if (isMounted) setWidth(window.innerWidth);
    });

    return () => {
      isMounted = false;
      window.removeEventListener("resize", () => setWidth(window.innerWidth));
    };
  }, []);

  const pois = [
    { name: "balna", src: balna },
    { name: "budaivar", src: budaivar },
    { name: "gellert", src: furdo },
    { name: "gresham", src: gresham },
    { name: "halasz", src: halasz },
    { name: "mupa", src: mupa },
    { name: "muszaki", src: muszaki },
    { name: "nemzeti", src: nemzeti },
    { name: "parlament", src: parli },
    { name: "szabadsag", src: szabadsag },
    { name: "templom", src: templom },
    { name: "vasar", src: vasar },
  ];

  let languages = {
    en: {
      short: "Eng",
      title: "English",
      flag: en,
    },
    de: {
      short: "Ger",
      title: "Deutsch",
      flag: de,
    },
    hu: {
      short: "Hun",
      title: "Magyar",
      flag: hu,
    },
    es: {
      short: "Esp",
      title: "Español",
      flag: es,
    },
    it: {
      short: "Ita",
      title: "Italiano",
      flag: it,
    },
    fr: {
      short: "Fra",
      title: "Français",
      flag: fr,
    },
    ru: {
      short: "Rus",
      title: "РУССКИЙ",
      flag: ru,
    },
    zh: {
      short: "Chn",
      title: "中国",
      flag: zh,
    },
    ro: {
      short: "Rom",
      title: "Română",
      flag: ro,
    },
    ko: {
      short: "Kor",
      title: "한국인",
      flag: kor,
    },
    el: {
      short: "Grc",
      title: "Ελληνικά",
      flag: grc,
    },
    cs: {
      short: "Cze",
      title: "Český",
      flag: cs,
    },
    pl: {
      short: "Pol",
      title: "Polski",
      flag: pl,
    },
  };

  return (
    <>
      <div id="route" className={`route-wrap`}>
        {width <= 1000 && (
          <>
            <h2 className="route-h mobile-h">{t("main1.route.h")}</h2>
            <p className="desc">{t("main1.route.p2")}</p>
          </>
        )}
        <div className="map-routes-section  bg-light">
          <div className="map-routes__routepic-container">
            {i18n.language === "hu" ? (
              <img className="map" loading="lazy" src={maphu} alt="map" />
            ) : (
              <img className="map" loading="lazy" src={mapen} alt="map" />
            )}
            {pois.map((e, i) => (
              <div className={`${e.name} map-poi`} style={{ position: "absolute" }} key={i}>
                <img src={e.src} alt={t(`main1.cruises.alts.${e.name}`)} />
                <p>{t(`main1.sightsPage.${e.name}.title`)}</p>
              </div>
            ))}
            <div className="buda">BUDA</div>
            <div className="pest">PEST</div>
          </div>

          {width <= 1000 && (
            <div className="mobile">
              <div className="inner-p-wrap">
                <>
                  <div className="map-routes-desc__departure-wrapper">
                    <div className="departure__text">
                      <b className="uppercase">{t("main1.sightsPage.departure")}</b>
                      <p className="uppercase light">{t("main1.sightsPage.address")}</p>
                    </div>
                  </div>

                  <div className="catalog-wrapper">
                    <img className="icon" src={hp} alt="icon" />
                    <div className="flex">
                      <h4>
                        {t("main1.route.guide")}
                        <br></br>
                        <span> {t("main1.route.guide2")}</span>
                      </h4>

                      <ul>
                        <li>
                          <Trans i18nKey={"main1.route.guideP1"}></Trans>
                        </li>
                        <li>
                          <Trans>
                            {t("main1.route.guideP2", { langs: Object.keys(languages).length })}
                          </Trans>
                        </li>
                        <li>
                          <Trans i18nKey={"main1.route.guideP3"}></Trans>{" "}
                          <Trans i18nKey={"main1.route.guideP4"}></Trans>
                        </li>

                        <li>
                          <Trans i18nKey={"main1.route.guideP5"}></Trans>
                        </li>
                        <li>
                          <Trans i18nKey={"main1.route.guideP6"}></Trans>
                        </li>
                      </ul>
                    </div>
                    <div className="hp-warning">
                      <p>{t("main1.route.hp")}</p>
                    </div>
                    <div className="languages-wrap">
                      <b className="uppercase">{t("main1.route.lang")}</b>
                      <div className="languages">
                        {Object.values(languages).map((e, i) => {
                          return (
                            <div key={i} className="flag-wrap">
                              <img src={e.flag} alt={e.short} />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          )}
          {width > 1000 && (
            <div className="desktop-wrap">
              <h2 className="route-h mobile-h">{t("main1.route.h")}</h2>
              <h2 className="route-h desktop-h">{t("main1.route.h")}</h2>

              <p className="desc">{t("main1.route.p2")}</p>
              <div className="inner-p-wrap">
                <div className="map-routes-desc__departure-wrapper">
                  <div className="departure__text">
                    <b className="uppercase">{t("main1.sightsPage.departure")}</b>
                    <p className="uppercase light">{t("main1.sightsPage.address")}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {width > 1000 && (
        <div className="guide-wrap desktop">
          <div className="imgs-wrap">
            <img src={poiimg1} alt="" />
            <img src={poiimg2} alt="" />
            <img src={poiimg3} alt="" />
            <img src={poiimg4} alt="" />
            <img src={poiimg5} alt="" />
            <img src={poiimg6} alt="" />
            <img src={poiimg7} alt="" />
          </div>
          <div className="text-phone-wrap">
            <div className="catalog-wrapper">
              <img className="icon" src={hp} alt="icon" />
              <div className="flex">
                <h4>
                  {t("main1.route.guide")}
                  <br></br>
                  <span> {t("main1.route.guide2")}</span>
                </h4>
                <div className="hp-warning">
                  <p>{t("main1.route.hp")}</p>
                </div>
                <ul>
                  <li>
                    <Trans i18nKey={"main1.route.guideP1"}></Trans>
                  </li>
                  <li>
                    <Trans>
                      {t("main1.route.guideP2", { langs: Object.keys(languages).length })}
                    </Trans>{" "}
                  </li>
                  <li>
                    <Trans i18nKey={"main1.route.guideP3"}></Trans>{" "}
                    <Trans i18nKey={"main1.route.guideP4"}></Trans>
                  </li>

                  <li>
                    <Trans i18nKey={"main1.route.guideP5"}></Trans>
                  </li>
                  <li>
                    <Trans i18nKey={"main1.route.guideP6"}></Trans>
                  </li>
                </ul>
              </div>

              <div className="languages-wrap">
                <b className="uppercase">{t("main1.route.lang")}</b>
                <div className="languages">
                  {Object.values(languages).map((e, i) => {
                    return (
                      <div key={i} className="flag-wrap">
                        <img src={e.flag} alt={e.short} />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="phone">
              <img src={phone} alt="" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MapRoutes;
