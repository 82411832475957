import star from "../imgs/icons/star-gold.svg";
import quote from "../imgs/icons/quote.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
const ReviewItem = ({ data }) => {
  return data.rating >= 4 ? (
    <div className="review-item">
      {/* <div className="review-head">
        <p className="author">{data.author_name}</p>
        <img referrerPolicy="no-referrer" style={{ width: 50, height: 50, borderRadius: "50%" }} src={data.profile_photo_url} />
      </div> */}
      <div className={`review-text-wrap `}>
        <div className="quote">
          <div className="img-wrap">
            <img src={quote} alt="" />
            <img src={quote} alt="" />
          </div>
        </div>
        <ReadMore>{data.text}</ReadMore>
      </div>
      <div className="review-foot">
        <div className="stars-wrap">
          {[...Array(data.rating)].map((e, i) => (
            <img key={i} style={{ height: 20, width: 20 }} src={star} alt="*" />
          ))}
        </div>
        {/* <p className="time">{data.relative_time_description}</p> */}
        <div style={{ display: "flex", gap: 10, alignItems: "center", justifyContent: "flex-end" }}>
          <p className="author">{data.author_name.split(" ").slice(0, 1).join("")}</p>
          <img referrerPolicy="no-referrer" style={{ width: 25, height: 25, borderRadius: "50%" }} src={data.profile_photo_url} />
        </div>
      </div>
    </div>
  ) : null;
};
const ReadMore = ({ children }) => {
  const { t } = useTranslation();
  const [readMore, setReadMore] = useState(true);

  return (
    <p className="review-text">
      {readMore ? children.split(" ").slice(0, 20).join(" ") : children}
      {}
      <span className={`readmore ${children.split(" ").length < 19 ? "hidden" : ""}`} onClick={() => setReadMore(!readMore)}>
        {readMore ? t("main1.reviews.more") : t("main1.reviews.less")}{" "}
      </span>
    </p>
  );
};
export default ReviewItem;
