import React, { useState, useEffect } from "react";
import { GoogleMap, useLoadScript, MarkerF, InfoWindow } from "@react-google-maps/api";

import customPin from "../imgs/icons/google-map-icon-black.svg";
import p1 from "../imgs/dock10.webp";
import Reviews from "./Reviews";

const containerStyle2 = {
  width: "100%",
  minHeight: "600px",
  height: "100vh",
};

const containerStyle = {
  width: "100%",
  minHeight: "600px",
};

const center1000 = {
  lat: 47.48714662309678,
  lng: 19.05052822374508,
};

const center1200 = {
  lat: 47.49232233428374,
  lng: 19.05016719479282,
};

const center1200HigherWidth = {
  lat: 47.4923962420545,
  lng: 19.049278066144108,
};

const divStyle = {
  padding: 10,

  color: "black",
};

const position = {
  lat: 47.49237948699274,
  lng: 19.050207985283997,
};

function Map({ width, t }) {
  const [isOpern, SetOpen] = useState(false);

  const [map, setMap] = useState(null);
  const [actualCoords, setCoords] = useState(position);
  const [actualZoom, setZoom] = useState(16);
  const [actualSize, setStyle] = useState(containerStyle2);

  useEffect(() => {});
  const handleToggleOpen = () => {
    isOpern ? SetOpen(false) : SetOpen(true);
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries: ["places"],
  });

  useEffect(() => {
    if (width < 1000) {
      setZoom(15);
      setCoords(center1000);
      setStyle(containerStyle2);
    } else if (width < 1200 && width > 1000) {
      setZoom(16);
      setCoords(center1200);
      setStyle(containerStyle2);
    } else {
      setZoom(16);
      setCoords(center1200HigherWidth);
      setStyle(containerStyle);
    }

    function handleResize() {
      if (width < 1000) {
        setZoom(16);
        setCoords(center1000);
        setStyle(containerStyle2);
      } else if (width < 1200 && width > 1000) {
        setZoom(16);
        setCoords(center1200);
        setStyle(containerStyle2);
      } else {
        setZoom(16);
        setCoords(center1200HigherWidth);
        setStyle(containerStyle);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading maps";

  return (
    <>
      <div id="google-map" style={{ position: "relative" }} className="Map">
        <GoogleMap
          mapContainerStyle={actualSize}
          center={actualCoords}
          zoom={actualZoom}
          onLoad={(map) => new Promise((res, rej) => res(setMap(map)))}
          className="map-style-container">
          <div className="find-us">
            <h3>{t("main1.findus.h")}</h3>
            <p>{t("main1.findus.p")}</p>
            <div className="second">{width > 500 ? <img src={p1} alt={t("AltTexts.Home.Map")} /> : null}</div>
          </div>
          <MarkerF
            onClick={handleToggleOpen}
            icon={{
              url: customPin,
            }}
            position={position}
            label={{ className: "label-class", text: "Dock 10" }}
            title="DC"></MarkerF>
        </GoogleMap>
        {/* {props.children} */}
      </div>
      <Reviews isLoaded={isLoaded} map={map} />
    </>
  );
}

export default Map;
