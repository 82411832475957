import vbLogo from "../imgs/vb-logo-white.svg";
import santa from "../imgs/sr-logo-text-light.svg";
import kisf from "../imgs/kisfaludy_logo_white.png";
import dcLogo from "../imgs/dc-logo-white.svg";
import seta from "../imgs/setahajo-logo--white@256px.png";
import ssBpLogo from "../imgs/icons/icon-parlament-white.svg";

const Partners = ({ lang }) => {
  return (
    <div className="partners-wrap ">
      <div className="partner-icon">
        <a className="vb" href={`https://visit-budapest.com/${lang}`}>
          <img src={vbLogo} alt="Visit Budapest" />
          <span>Visit Budapest</span>
        </a>
      </div>
      <div className="partner-icon ">
        <a className="dc" href={`https://dunacruises.com/${lang}`}>
          <img src={dcLogo} alt="Duna Cruises" />
        </a>
      </div>
      <div className="partner-icon ">
        <a className="ss-bp" href={`https://sightseeing-budapest.com`}>
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "grid",
              gridTemplateRows: "40px 10px",
            }}>
            <img src={ssBpLogo} alt="Sightseeing Budapest" />
            <span style={{ textTransform: "uppercase" }}>Sightseeing Budapest</span>
          </div>
        </a>
      </div>
      <div className="partner-icon ">
        <a className="kisf" href={`https://kisfaludygozos.hu`}>
          <img src={kisf} alt="Kisfaludy Gőzös" />
        </a>
      </div>
      <div className="partner-icon ">
        <a href="https://setahajo.hu" className="setahajo">
          <img src={seta} alt="Sétahajó" />
        </a>
      </div>
    </div>
  );
};
export default Partners;
