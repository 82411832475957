import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/css";
import img1_s from "../imgs/gallery/360/1.webp";
import img1_m from "../imgs/gallery/600/1.webp";
import img1_l from "../imgs/gallery/1366/1.webp";
import img2_s from "../imgs/gallery/360/2.webp";
import img2_m from "../imgs/gallery/600/2.webp";
import img2_l from "../imgs/gallery/1366/2.webp";
import img3_s from "../imgs/gallery/360/3.webp";
import img3_m from "../imgs/gallery/600/3.webp";
import img3_l from "../imgs/gallery/1366/3.webp";

import img6_s from "../imgs/gallery/360/6.webp";
import img6_m from "../imgs/gallery/600/6.webp";
import img6_l from "../imgs/gallery/1366/6.webp";

import img8_s from "../imgs/gallery/360/8.webp";
import img8_m from "../imgs/gallery/600/8.webp";
import img8_l from "../imgs/gallery/1366/8.webp";
import img9_s from "../imgs/gallery/360/9.webp";
import img9_m from "../imgs/gallery/600/9.webp";
import img9_l from "../imgs/gallery/1366/9.webp";

import img13_s from "../imgs/gallery/360/13.webp";
import img13_m from "../imgs/gallery/600/13.webp";
import img13_l from "../imgs/gallery/1366/13.webp";

import img15_s from "../imgs/gallery/360/15.webp";
import img15_m from "../imgs/gallery/600/15.webp";
import img15_l from "../imgs/gallery/1366/15.webp";
import img16_s from "../imgs/gallery/360/16.webp";
import img16_m from "../imgs/gallery/600/16.webp";
import img16_l from "../imgs/gallery/1366/16.webp";

import img18_s from "../imgs/gallery/360/18.webp";
import img18_m from "../imgs/gallery/600/18.webp";
import img18_l from "../imgs/gallery/1366/18.webp";

import img20_s from "../imgs/gallery/360/38.webp";
import img20_m from "../imgs/gallery/600/38.webp";
import img20_l from "../imgs/gallery/1366/38.webp";
import { useState, useEffect } from "react";

const Gallery = ({ t }) => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    let isMounted = true;
    window.addEventListener("resize", () => {
      if (isMounted) setWidth(window.innerWidth);
    });

    return () => {
      isMounted = false;
      window.removeEventListener("resize", () => setWidth(window.innerWidth));
    };
  }, []);
  const getImgs = () => {
    const elements = document.querySelectorAll(` .swiper-slide-visible`);
    if (elements.length > 0 && elements[elements.length - 1].nextSibling) {
      elements.forEach((e) => e.children[0].setAttribute("loading", "eager"));
      elements[elements.length - 1].nextSibling.children[0].setAttribute("loading", "eager");
    }
  };
  const gallery = [
    { small: img18_s, med: img18_m, big: img18_l, alt: "main1.gallery.alts.18" },
    { small: img1_s, med: img1_m, big: img1_l, alt: "main1.gallery.alts.1" },
    { small: img15_s, med: img15_m, big: img15_l, alt: "main1.gallery.alts.15" },
    { small: img6_s, med: img6_m, big: img6_l, alt: "main1.gallery.alts.6" },
    { small: img3_s, med: img3_m, big: img3_l, alt: "main1.gallery.alts.1" },

    { small: img8_s, med: img8_m, big: img8_l, alt: "main1.gallery.alts.8" },
    { small: img9_s, med: img9_m, big: img9_l, alt: "main1.gallery.alts.9" },
    { small: img16_s, med: img16_m, big: img16_l, alt: "main1.gallery.alts.16" },
    { small: img2_s, med: img2_m, big: img2_l, alt: "main1.gallery.alts.1" },

    { small: img13_s, med: img13_m, big: img13_l, alt: "main1.gallery.alts.13" },

    { small: img20_s, med: img20_m, big: img20_l, alt: "main1.gallery.alts.20" },
  ];
  const mappedGallery = gallery.map((e, i) => {
    return (
      <SwiperSlide key={i}>
        <img
          loading="lazy"
          src={width <= 380 ? e.small : width <= 1000 ? e.med : e.big}
          alt={t([e.alt, "main1.gallery.alts.fallback"])}
          className="img-container-drinks"
          style={{
            objectFit: "cover",
            height: "100%",
            width: "100%",
            minHeight: "100%",
            minWidth: "100%",
          }}
        />
      </SwiperSlide>
    );
  });
  return (
    <div>
      <div className="head-wrap">
        <div className="dot"></div>
        <h2>{t("main1.gallery.h2")}</h2>
        <div className="dot"></div>
      </div>

      <Swiper
        watchSlidesProgress
        onSlideChange={() => getImgs("drinks-gallery-wrap")}
        modules={[Navigation, Autoplay]}
        slidesPerView={width > 1100 ? Math.round(width / 400) : width < 550 ? 1 : 2}
        spaceBetween={10}
        autoplay={{ delay: 6000, disableOnInteraction: true }}
        speed={600}
        navigation
        // centeredSlides
        loop>
        {mappedGallery}
      </Swiper>
    </div>
  );
};
export default Gallery;
