import i18next from "i18next";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TimetableItem from "./components/TimetableItem";
import MapRoutes from "./components/mapRoutes";
import elegant from "./imgs/elegant.webp";
import historical from "./imgs/historical.webp";
import music from "./imgs/music.webp";
import logo from "./imgs/boattoursbp-logo-emblem-white.svg";
import Partners from "./components/Partners";
import Gallery from "./components/Gallery";
import WelcomeDrink from "./components/WelcomeDrink";
import Map from "./components/Map";
import VidPlaceholder from "./components/Viedo";

const LandingPage = ({ t }) => {
  const langData = [
    { short: "Hun", flag: "--hun", long: "Magyar", abbr: "hu" },
    { short: "Eng", flag: "--eng", long: "English", abbr: "en" },
    { short: "Ger", flag: "--ger", long: "Deutsch", abbr: "de" },
    { short: "Fra", flag: "--fra", long: "Français", abbr: "fr" },
    { short: "Ita", flag: "--ita", long: "Italiano", abbr: "it" },
    { short: "Esp", flag: "--spa", long: "Español", abbr: "es" },
    { short: "Chi", flag: "--chi", long: "中国", abbr: "zh" },
    { short: "Rus", flag: "--rus", long: "РУССКИЙ", abbr: "ru" },
    { short: "Rom", flag: "--rom", long: "Română", abbr: "ro" },
    { short: "Kor", flag: "--kor", long: "한국인", abbr: "ko" },
    { short: "Grc", flag: "--grc", long: " Ελληνικά", abbr: "el" },
  ];

  const newprices = true;
  const lang = i18next.language;
  const [langOpen, setLangOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [currentLang, setCurrentLang] = useState(
    langData.find((e) => e.abbr === lang)
      ? langData.find((e) => e.abbr === lang)
      : { short: "en", flag: "--eng" }
  );
  const [hidden, setHidden] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    window.addEventListener("resize", () => {
      if (isMounted) setWidth(window.innerWidth);
    });
    window.addEventListener("scroll", () => {
      if (window.scrollY > 500 && hidden) return setHidden(false);
      if (window.scrollY <= 500 && !hidden) setHidden(true);
    });

    return () => {
      isMounted = false;
      window.removeEventListener("resize", () => setWidth(window.innerWidth));
    };
  }, []);
  useEffect(() => {
    if (i18next.language === window.location.pathname.slice(1)) return;
    const availableLanguages = Object.values(langData).map((e) => e.abbr);

    if (availableLanguages.find((e) => e === window.location.pathname.slice(1))) {
      i18next
        .changeLanguage(window.location.pathname.slice(1))
        .then(() =>
          setCurrentLang(langData.find((e) => e.abbr === window.location.pathname.slice(1)))
        );
    } else navigate("/en");
  }, [lang]);

  return (
    <>
      <div className="sights-page">
        <div
          className={`to-top ${hidden ? "hidden" : ""}`}
          onClick={() => {
            window.scroll({ top: 0, left: 0, behavior: "smooth" });
          }}>
          <div className="line"> </div>
        </div>
        <header className="bp-nav">
          <div className="brand">
            <img src={logo} alt={"SIGHTSEEING BUDAPEST"} />
            BOAT TOURS BUDAPEST
          </div>

          <div className="current-lang" onClick={() => setLangOpen(!langOpen)}>
            <div className={`flag ${currentLang.flag}`}></div>
            <div className="short">{currentLang.short}</div>
          </div>
        </header>
        <div className={`lang-selector ${langOpen ? "" : "hidden"}`}>
          <p>Choose Language</p>
          <div className="close" onClick={() => setLangOpen(false)}></div>
          {langData.map((e, i) => {
            return (
              <div
                className="lang-item"
                key={i}
                onClick={() => {
                  setLangOpen(false);
                  setCurrentLang(e);
                  i18next.changeLanguage(e.abbr).then(() => {
                    return navigate(`/${e.abbr}`);
                  });
                }}>
                <a style={{ display: "none" }} hrefLang={e.abbr}></a>
                <div className={`flag ${e.flag}`}></div>
                <div className="lang">{e.long}</div>
              </div>
            );
          })}
        </div>
        <div className="hero">
          <div className="hero-img"></div>
          <div className="hero-filter"></div>
          <div className="hero-text">
            <h1>
              {t("main1.hero.h")}
              <br></br>
              {t("main1.hero.h2")}
            </h1>

            <p>{t("main1.hero.p")}</p>
            {/* <a
              className=" btn btn--arrow  s"
              href="#program"
              onClick={(e) => {
                e.preventDefault();
                const ele = document.getElementById("program");
                ele.scrollIntoView({ behavior: "smooth" });
              }}>
              <span className="btn__text">{t("main1.sightsPage.heroBtn")}</span>
              <span className="btn__icon">
                <div className="icon--arrow arrow-dwn"></div>
              </span>
            </a> */}
          </div>
        </div>
        <div className="pois-section">
          <div className="pois-text" id="program">
            <h2>{t("main1.sightsPage.shipTitleB")}</h2>
            <p style={{ marginTop: 30 }}> {t("main1.intro.p1")} </p>
            <p style={{ marginTop: 10 }}>{t("main1.intro.p2")}</p>
            <p style={{ marginTop: 10 }}>{t("main1.intro.p3")}</p>
            {/* <div className="long-arrow"></div> */}
          </div>
        </div>

        <div className="tours-section" id="tours">
          <h2>{t("main1.sightsPage.shipTitle")}</h2>
          <div className="tours-wrap">
            <div className="tour">
              <div className="text-wrap">
                <h3>{t("main1.cruises.elegant")}</h3>
                <p>{t("main1.cruises.elegantP")}</p>
                <div className="btn">
                  <a
                    href={`https://fareharbor.com/embeds/book/dunacruises/items/calendar/?full-items=yes&flow=823770&language=${lang}&view=all-availability`}>
                    {t("main1.schedule.tickets")}
                  </a>
                </div>
              </div>
              <div className="img-wrap">
                <img src={elegant} alt={t("main1.cruises.dayH")} />
              </div>
            </div>
            <div className="tour tour-b">
              <div className="img-wrap">
                <img src={historical} alt={t("main1.cruises.elegant")} />
              </div>
              <div className="text-wrap">
                <h3>{t("main1.cruises.historical")}</h3>
                <p>{t("main1.cruises.historicalP")}</p>
                <div className="btn">
                  <a
                    href={`https://fareharbor.com/embeds/book/dunacruises/items/calendar/?full-items=yes&flow=1185435&language=${lang}&view=all-availability`}>
                    {t("main1.schedule.tickets")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gallery">
          <Gallery t={t} />
        </div>
        <div className="route-section">
          <MapRoutes t={t} route="sightseeing" width={width} />
        </div>
        <div className="drink-section">
          <WelcomeDrink t={t} />
        </div>
        {/* <div className="video-section">
          <VidPlaceholder t={t} />
        </div> */}
        <div className="map-section">
          <Map width={width} t={t} />
        </div>
      </div>
      <footer>
        <div className="info">
          <p style={{ fontWeight: 500, marginBottom: 20 }}>BOAT TOURS BUDAPEST</p>

          <p className="rights">All rights reserved {new Date().getFullYear()}</p>
        </div>
        <div className="partners-section">
          <p className="partners">{t("main1.sightsPage.partner")}</p>
          <Partners lang={lang} />
        </div>
      </footer>
    </>
  );
};
export default LandingPage;
