import img from "../imgs/gallery/1366/3.webp";
const WelcomeDrink = ({ t }) => {
  return (
    <div className="welcome-wrap">
      <div className="text-wrap">
        <h2>{t("main1.service.welcomeH")}</h2>
        <p>{t("main1.service.welcomeP")}</p>
      </div>
      <div className="img-wrap">
        <img src={img} alt="welcome drink" />
      </div>
    </div>
  );
};
export default WelcomeDrink;
